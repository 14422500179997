@use 'styles/_variables.scss' as variables;

.mainBoxedContainer {
    padding: 16px;
    border: 1px solid #58798F80;
    border-radius: 8px;
    margin-top: 20px;
    overflow: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
}

.mainBoxedContainerMobileView {
    display: flex;
    align-items: center;
}

.expandIcon {
    margin-left: auto;
}

.expandableSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cecece;
    flex-grow: 100;
    padding: 20px 0;
    margin-left: 10px;
    cursor: pointer;
}

@media (min-width: variables.$screen-xs-max ) {
    .mainBoxedContainerMobileView {
        display: none;
    }
}

@media (max-width: variables.$screen-xs-max) {
    .mainBoxedContainer {
        display: none;
    }
}

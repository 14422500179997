@use '../styles/variables' as vars;

.alertBox{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 0px;
  padding: 9px;
  --goto-icon-color: #000;
}
.dangerBackground{
  background-color: #e02d52;
  --goto-icon-color: white;
  chameleon-button::part(control){
    box-shadow: inset 0 0 0 1px white ;
  }
}

.bannerMessageText {
  display: inline-flex;
}

.closeContainer {
  position: absolute;
  cursor: pointer;
  right: 15px;
  font-size: 16px;
  top: 14px;
}
@media (max-width: vars.$screen-xs-max) {
  .content {
    display: flex;
    gap: 4px;
    margin-right: 20px;
  }
  .icon{
    margin-top: 3px;
  }
}
@media (min-width: vars.$screen-xs-max) {
  .content {
    display: flex;
    gap: 4px;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
  }
  .alertHeading {
    padding-top: 3px;
    i {
      font-size: 1.1em;
      vertical-align: bottom;
    }
  }
  .alertDescription {
    padding-top: 9px;
  }
  .alertButtons {
    float: right;
    margin-right: 5px;
    margin-top: 2px;
  }
}

@use 'styles/_variables.scss' as vars;

.iconGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 14px;
    padding: 14px;
    align-items: center;
}

.navContainer {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px  solid #58798F80;
    height: 49px;
    border-radius: 0;
    display: flex;
}

.menuOptions {
  --trigger-x: 0 !important;
}

.supportLinks{
    display: flex;
}

.navIcon {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.avatar-nav {
    background: none;
    border: none;
    cursor: pointer;
}

.avatarInfo {
    display: flex;
    padding: 6px 10px;
    gap: 7px;
    word-break: break-word;
}

.copyRightBlock {
    display: flex;
    justify-content: center;
    height: 24px;
}
.sideNavToggle {
    display: inline-block;
}

@media (max-width: vars.$screen-xs-max) {
    .sideNavToggle, .avatar-nav, .supportLinks {
        display: none;
    }
}

@media (min-width: vars.$screen-xs-max ) {
    .fluidOptions{
        display: none;
    }
}
.popover{
  chameleon-icon-button::part(control){
    outline: none !important;
  }
}
@media (max-width: 733px ) {
  .popover{
    --element-padding: 3px !important;
  }
}


.acceptInvitationContainer {
  max-width: 620px;
  margin: 0 auto;
}

.titleText {
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 26px;
  color: #444444;
  margin-top: 32px;
  margin-bottom: 12px;
}

.bodyText,
.boldText,
.pText
{
  font-family: lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin-top: 18px;
}

.boldText {
  font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.59px;
  color: #000000;
}

.transferIcon {
  margin: auto;
}

p.boldText {
  padding-top: 10px;
}

.pText {
  margin-top: 24px;
}

.bodyRow {
  margin-top: 12px;
}

.whathapensBody {
  margin-top: 15px;

}

.bulletPoints {
  margin-top: 20px;
  padding-left: 24px;
  list-style: none;
  li {
    margin-bottom: 10px;
    &::before {
      content: "\2022";
      color: #000000;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
    }
    span {
      padding-left: 6px;
    }
  }
}
.mainForm {
  margin-top: -10px;
}

.productsRow {
  display: flex;
  gap:40px;
}
.productsColumn {
  border-radius: 8px;
  padding: 16px 20px;
  border: 1px solid #58798F80 ;
}

.titleRow {
  margin-bottom: 24px;
}

.gapedText {
  display: grid;
  gap: 16px;
}

.infoTextPointer {
  display: flex;
}

.acceptInvitationBlock {
  height: inherit;
  display: grid;
  align-items: center;
}

@media (min-width: 768px) {
  .productsColumn {
    width: 47%;
    float: left;
  }
  .arrowColumn {
    width: 6%;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    height: 100px;
  }
}

.buttonRow {
  margin-top: 36px;
  margin-bottom: 20px;
  display: flex;
  gap: 10px
}



@media (max-width: 768px) {
  button {
    max-width: 360px;
  }
  .buttonRow {
    text-align: center;
    display: grid;
  }
  .arrowColumn {
    text-align: center;
    vertical-align: middle;
    padding: 0;
    height: 36px;
  }
  .verticalArrow {
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-top: 18px solid #b3bfd0;
    border-right: 18px solid transparent;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
  }
}

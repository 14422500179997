@use './variables' as vars;

:global {

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
  }
  .emailText {
    white-space: nowrap;
    font-weight: bold;
  }
  .loader-background {
    fill: none;
    stroke-width: 2px;
    stroke: #f7f7f7;
  }

  .loader-container {
      width: 32px;
      display: flex;
      margin: auto;
      height: 100vh;
      align-items: center;
  }

  .loader {
    fill: none;
    stroke: #2c72de;
    stroke-width: 2px;
    stroke-dashoffset: 10;
    stroke-dasharray: 20;
    transform-origin: 50% 50% 0px;
    transform: rotate(-90deg);
    -webkit-animation:spin-infinite 0.7s linear infinite;
    -moz-animation: spin-infinite 0.7s linear infinite;
    -o-animation: spin-infinite 0.7s linear infinite;
    -ms-transition: spin-infinite 0.7s linear infinite;
    transition: spin-infinite 0.7s linear infinite;
    animation: spin-infinite 0.7s linear infinite;
  }

  .learnMoreLink {
    color: vars.$default-text-color !important;
    text-decoration: underline !important;
    text-underline-offset: 2px;
  }

  .coloredTextForStrength {
    color: #1B8756;
    font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  //Nav bar overrides

// NavBar "gray band" zone

  .no-margin {
    margin: 0 !important;
  }

  .form-control {
    height: 45px;
    border-color: #D8D8D8;
    color: #444;
  }

  .form-control:focus {
    border: 2px solid #469FFF;
  }

  /*floating labels end*/

  DIV .has-error {
    label {
      color: vars.$error-red;
    }

    .help-block {
      font-size: 11px;
      text-align: left;
      background-color: #ffffff;
      color: vars.$error-red;
      padding-bottom: 0;
    }

    .form-control {
      border: 1px solid #f53d5a;
    }

    .input-group-addon {
      color: #fff;
      background-color: vars.$error-red;
      border-color: vars.$error-red;
    }
  }

  // Form, and form field formatting styles
  form {
    margin-top: 20px;
  }

  // Form -align left - temporary -  to make field labels align left -- JSN
  form {
    text-align: left;
  }


  .form-group,
  .input-group {
    margin-bottom: 25px;

    &.shallow {
      margin-bottom: 18px;
    }

    .static-field {
      padding-top: 12px;
      padding-bottom: 11px;
      color: #767676;
      cursor: default;
      overflow: hidden;
    }
  }

  @media (max-width: vars.$screen-xs-max) {
    .app-content {
      padding-bottom: 175px;
    }

    .app-container-inner {
      margin-bottom: -58px
    }
    .navbar-lite {
      .navbar-brand-lite {
        background-position: 0 0 !important;
      }
    }
  }

  @media (min-width: 480px) {
    .navbar-lite {
      .navbar-brand-lite {
        padding-left: 32px;
      }
    }
  }

  .regularText {
    font-family: lato-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .modalContent .contentDivPadding {
    text-align: left;
    padding-top: 16px;
  }

}

.mainContainer {
  display: flex;
  column-gap: 20px;
}

.singleColumn {
  flex: 1 1 0;
  min-width: 0;
  max-width: 33%;
  box-sizing: border-box;
}

@media (max-width: vars.$screen-xs-max) {
  .singleColumn {
    width: 100%;
    max-width: 100%;
  }
  .pageHeader {
    margin: 8px auto 24px auto;
  }
  .mainContainer {
    display: contents;
   }
}

.pageHeader {
  margin-bottom: 16px;
}

a {
  text-decoration: auto !important;
  color: #2C72DE !important;
}


.centerAnyContent {
  height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-state {
  background-color: rgba(44, 114, 222, 0.1);
}

.navbar-brand {
  margin-right: 15px;
}

// general
.no-margin {
  margin: 0;
}

hr {
  border-top-color: vars.$mid-gray;
  margin: 0;
}




// TEMPORARY OVERRIDES - INCORPORATE INTO BASE LIBRARY - IF NEEDED
// ToGo anchor link color change
a {
  color: #479ffe;
}

//Max width of content not to exceed 1200 pixels

.navbar {
  margin-bottom: 0;
}



// Container overrides

.app-container {
  min-height: 100%;
}

.app-container-inner {
  min-height: 100%;
  margin-bottom: -50px
}


/* Button styles and overrides */
.buttonRow {
  margin-top: 40px;
  margin-bottom: 20px;
}


.dropBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0;
  padding: 10px 16px 20px;
  border: 2px dashed rgba(137, 151, 173, .3);
  border-radius: 5px;
  -webkit-transition: border 0.2s;
}

.dropBox.hover {
  border: 2px solid #2684ec;
}

.dropBox.hover DIV,
.dropBox.hover I {
  color: #2684ec;
}

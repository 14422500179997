@use '../../styles/variables' as variables;

html {
  overflow: hidden;
}

body {
  margin: 0;
  color: #25282D !important;
}

.mainDisplay {
  flex-grow: 100;
  padding: 32px 32px 45px 32px;
  overflow: auto;
  height: calc(100vh - 113px);
}

@media (max-width: variables.$screen-xs-max) {
  .mainDisplay {
    padding: 16px;
    overflow-y: auto;
    display: inline-block;
  }
}

.fluidAppContainer {
  display: flex;
}

.notificationContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
}

.mainContainer {
  padding: 0;
}

.skipToMainContent a {
  padding: 9px;
  margin-left: 2px;
  margin-top: 2px;
  position: absolute;
  top: -50px;
  left: 0;
  color: #006aff;
  border: 1px solid #006aff;
  border-radius: 4px;
  background: #FFFFFF;
  -webkit-transition: top 1s ease-out;
  transition: top 1s ease-out;
  z-index: 100;

  &:focus {
    position: absolute;
    left: 0;
    top: 0;
    outline-color: transparent;
    outline: none;
    -webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
  }
}

@media (max-width: variables.$screen-sm-max) {
  .mainContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: variables.$screen-md-max) {
  .app-container {
    width: variables.$screen-md-max;
    margin: 0 auto;
  }
}

@use '../../../styles/variables' as variables;

.progress-padding {
  padding-top: 2em;
}

.error-view {
  padding-top: 80px;
  margin: 0 auto;
  width: 40%;
  text-align: center;

  .alignedContent {
    display: grid;
  }

  .errorInfo {
    display: flex;
    gap: 18px;
  }

  h5 {
    line-height: 1.5;
  }

  i {
    color: variables.$blue;
    font-size: 6.8em;
  }

  table {
    margin-top: 50px;
  }

  .error-details {
    margin-top: 10px;

    h5 {
      font-size: 1.1em;
      margin-bottom: -10px;
    }

    hr {
      margin: 15px 0;
    }
  }
}

.userInvitationContainer {
  max-width: 620px;
  margin: 0 auto;
}

.titleText {
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 26px;
  color: #444444;
  margin-top: 32px;
  margin-bottom: 12px;
}

.bodyRow {
  margin-top: 12px;
}

.bodyText,
.boldText {
  font-family: lato, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin-top: 20px;
}

.boldText {
  font-family: lato-semibold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0.59px;
  color: #000000;
}

.buttonRow {
  margin-top: 34px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  button {
    max-width: 360px;
  }
}


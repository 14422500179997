.loadingIcon {
  height: 19px;
  width: 19px;
}

.useCountDownTimerBlock {
  display: flex;
  gap: 7px;
  margin-top: 30px;
}


.confirmDialogTitle {
  font-family: lato-bold, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgb(37, 40, 45);
  font-size: 18px;
}

.confirmDialogBody,
.countDownTimer {
  font-family: lato-regular, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: rgb(37, 40, 45);
  font-size: 14px;
}

.modal {
  position: relative;
  top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  text-align: left;
  max-width: 540px;
  background-color: #FFFFFF;
  box-shadow: 4px 4px 2px #888888;
  overflow: auto;
  i {
    font-size: 18px;
    font-weight: 600;
  }
  .confirmDialogBody {
    margin: 26px 0 36px;
    &.shallow {
      margin-bottom: 24px;
    }
  }
  .countDownTimer {
    margin-bottom: 18px;
    span:first-child {
      margin-right: 4px;
    }
  }
  .dialogYesButton,
  .dialogNoButton {
    margin-bottom: 0;
    padding: 8px 45px;
  }
  .dialogNoButton {
    margin-right: 30px;
    background-color: #ffffff;
    border: none !important;
    &:hover {
      background-color: #ffffff;
      border: none !important;
      color: #004fbd !important
    }
  }
}
@media (max-width: 550px) {
  .modal {
    top: 0px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3); /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(circle, rgba(0,0,0,0.2), rgba(0,0,0,0.4)); /* For Safari 5.1 to 6.0 */
  background: -o-radial-gradient(circle, rgba(0,0,0,0.2), rgba(0,0,0,0.4)); /* For Opera 11.1 to 12.0 */
  background: -moz-radial-gradient(circle, rgba(0,0,0,0.2), rgba(0,0,0,0.4)); /* For Firefox 3.6 to 15 */
  background: radial-gradient(circle, rgba(0,0,0,0.2), rgba(0,0,0,0.4)); /* Standard syntax */
}

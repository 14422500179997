@use '../../../styles/variables' as variables;

.progress-padding {
  padding-top: 2em;
}

.unauthorized-view {
  padding-top: 12%;
  margin: 0 auto;
  width: 40%;
  text-align: center;

  .mainHeading {
    margin: 20px auto 40px auto;
  }

  .alignedContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h5 {
    line-height: 1.5;
  }

  i {
    color: variables.$blue;
    font-size: 6.8em;
  }
}

@use 'styles/_variables.scss' as variables;

.personalInfoInputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 8px;
}

.moreAboutYou {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

}

.inlineTextWithIcon {
    display: flex;
    align-items: center;
}

.pictureSelector {
    text-decoration: auto;
    color: #2C72DE !important;
}

.resetToDefaultText {
    display: flex;
    justify-content: center;
}

.dropBoxLine1{
    margin-top: 10px;
}

.sliders {
    display: flex;
    gap:22px;
    justify-content: center;
    margin-top: 16px;
    align-items: center;
}

.choseAnotherPictureText {
    margin-top: 32px;
}

.avatarActions {
    display: flex;
    flex-direction: row-reverse;
    min-height: 72px;
    column-gap: 8px;
    row-gap: 8px;
    align-items: center;
    position: absolute;
}

.avatarOuterContainer {
    display: flex;
    justify-content: end;
}

.editorRow {
  display:flex;
  align-items: center;
}

.editorRow div[class*='col-'] {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.editorRow canvas {
  border-radius: 5px;
}
.editorRow button {
  position: relative;
  top: -20px;
}

.editorRow button {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  border: solid 1px #b5b4b5;
  padding: 0;
  background-color: #FFFFFF;
}

.avatarEditorBlock {
  margin: 32px auto;
  display: grid;
  justify-items: center;

  :last-child{
    display: grid;
    justify-items: center;
  }
}

.editProfilePicBadge {
  background-color: #000000;
  height: 24px;
  width: 24px;
}

.avatarBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #000000;
  height: 26px;
  width: 26px;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

@media (max-width: variables.$screen-xs-max) {
  .fluidContainer {
    display: none;
  }
}

@media (min-width: variables.$screen-xs-max) {
  .avatarEditorBlock  {
    display: none;
  }
}
